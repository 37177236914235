import { ReactElement } from "react"
import App from "../App"
import { AppInterface } from "types/types"
import Link from "next/link"
import useT from "utils/useT"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import { Autoplay, Virtual } from "swiper/modules"
import useMediaQuery from "utils/useMediaQuery"
import { fetchGames } from "queries"
import useFetchApps from "utils/useFetchApps"
import MountIfVisible from "components/MountIfVisible"
import useRouterStore from "stores/routerStore"

export default function Apps(): ReactElement {
  const { data: games } = useFetchApps({
    queryFn: fetchGames,
    queryKey: ["getGames"],
  })
  const locale = useRouterStore((state) => state.router?.locale)
  const isArabic = locale?.includes("ar")
  const t = useT()
  const isDesktop = useMediaQuery("(min-width: 900px)")

  return (
    <>
      <div className="content mb-2">
        <h2 className={`float-${isArabic ? "end" : "start"} font-16 font-500`}>
          {t.home.latestGames}
        </h2>
        <Link
          href="/apps?switch=games"
          className={`float-${
            isArabic ? "start" : "end"
          } font-13 font-700 color-highlight mt-n1`}
        >
          {t.home.viewAll}
        </Link>
        <div className="clearfix"></div>
      </div>
      <div className="hot-list-wrapper overflow-hidden">
        <Swiper
          modules={[Autoplay, Virtual]}
          grabCursor={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          virtual
          style={{ overflow: "visible" }}
          slidesPerView={isDesktop ? 9 : 4}
          slidesPerGroup={isDesktop ? 1 : 2}
          className="mySwiper px-3"
          spaceBetween={16}
        >
          {games?.map((app: AppInterface, i: number) => (
            <SwiperSlide key={i}>
              <MountIfVisible i={i} defaultHeight={104.5} visibleOffset={-80}>
                <App
                  key={app.trackId + "game-slider"}
                  trackId={app.trackId}
                  artworkUrl512={app.artworkUrl512}
                  trackName={app.trackName}
                />
              </MountIfVisible>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}
