import { RefObject, useEffect, useState } from "react"
import { event } from "./gtag"

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean
}

function useIntersectionObserver(
  elementRef: RefObject<Element | null>,
  fetcher: () => void,
  isFetchingNextPage = false,
  {
    threshold = 0,
    root = null,
    rootMargin = "0%",
    freezeOnceVisible = false,
  }: Args,
  page?: "home" | "apps" | "games" | "allApps",
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>()

  const frozen = entry?.isIntersecting && freezeOnceVisible
  const isVisible = !!entry?.isIntersecting
  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry)
  }

  useEffect(() => {
    const node = elementRef?.current // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver

    if (!hasIOSupport || frozen || !node) return

    const observerParams = { threshold, root, rootMargin }
    const observer = new IntersectionObserver(updateEntry, observerParams)

    observer.observe(node)

    return () => observer.disconnect()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen])

  useEffect(() => {
    if (isVisible && !isFetchingNextPage) {
      fetcher()
      event({
        action: "load_more",
        category: "Load More Spinner",
        label: `Load more ${page}`,
        value: `${page} page`,
      })
    }
  }, [isVisible, isFetchingNextPage, fetcher, page])

  return entry
}

export default useIntersectionObserver
