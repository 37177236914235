import { ReactElement } from "react"
import App from "../App"
import { AppInterface } from "types/types"
import Link from "next/link"
import useT from "utils/useT"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import { Autoplay, Virtual } from "swiper/modules"
import useMediaQuery from "utils/useMediaQuery"
import useFetchApps from "utils/useFetchApps"
import { fetchApps } from "queries"
import MountIfVisible from "components/MountIfVisible"
import useRouterStore from "stores/routerStore"

export default function Apps(): ReactElement {
  const t = useT()
  const locale = useRouterStore((state) => state.router?.locale)
  const isArabic = locale?.includes("ar")
  const isDesktop = useMediaQuery("(min-width: 900px)")
  const { data: apps } = useFetchApps({
    queryFn: ({ pageParam }) => fetchApps({ pageParam }),
    queryKey: ["getApps"],
  })

  return (
    <>
      <div className="content mb-2 mt-n2">
        <h2 className={`float-${isArabic ? "end" : "start"} font-16 font-500`}>
          {t.home.latestApps}
        </h2>
        <Link
          href="/apps?switch=apps"
          className={`float-${
            isArabic ? "start" : "end"
          } font-13 font-700 color-highlight`}
        >
          {t.home.viewAll}
        </Link>
        <div className="clearfix"></div>
      </div>
      <div className="hot-list-wrapper overflow-hidden">
        <Swiper
          modules={[Autoplay, Virtual]}
          grabCursor={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          virtual
          style={{ overflow: "visible" }}
          slidesPerView={isDesktop ? 9 : 4}
          slidesPerGroup={isDesktop ? 1 : 2}
          className="mySwiper px-3"
          spaceBetween={16}
        >
          {apps?.map((app: AppInterface, i: number) => (
            <SwiperSlide key={i}>
              <MountIfVisible defaultHeight={104.5} i={i} visibleOffset={-80}>
                <App
                  i={i}
                  key={app.trackId + "app-slider"}
                  trackId={app.trackId}
                  artworkUrl512={app.artworkUrl512}
                  trackName={app.trackName}
                />
              </MountIfVisible>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}
