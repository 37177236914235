import AppsList from "components/AppsList"
import useT from "utils/useT"
import { fetchAll } from "queries"
import useFetchApps from "utils/useFetchApps"
import usePullToRefresh from "utils/usePullToRefresh"
import MainSliders from "components/sliders"
import useIntersectionObserver from "utils/useIntersectionObserver"
import { useRef } from "react"
import { useQueryClient } from "@tanstack/react-query"
import useFiltersStore from "stores/filters"

export default function Home() {
  const t = useT()
  const queryClient = useQueryClient()
  const price = useFiltersStore((state) => state.price)

  const {
    data: apps,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchApps({
    queryFn: ({ pageParam }) => fetchAll({ price, pageParam }),
    queryKey: ["getAll"],
  })
  const refetch = async () =>
    Promise.all([
      queryClient.refetchQueries({
        queryKey: ["getAll"],
        // fetch only the first page
        refetchPage: (lastPage: unknown, index: number) => index === 0,
      }),
      queryClient.refetchQueries({
        queryKey: ["getApps"],
        // fetch only the first page
        refetchPage: (lastPage: unknown, index: number) => index === 0,
      }),
      queryClient.refetchQueries({
        queryKey: ["getGames"],
        // fetch only the first page
        refetchPage: (lastPage: unknown, index: number) => index === 0,
      }),
      queryClient.refetchQueries({
        queryKey: ["getAll" + "discounted"],
        // fetch only the first page
        refetchPage: (lastPage: unknown, index: number) => index === 0,
      }),
    ])

  usePullToRefresh({ refetch, element: "#main-app", page: "home" })
  const ref = useRef<HTMLDivElement | null>(null)
  useIntersectionObserver(ref, fetchNextPage, isFetchingNextPage, {}, "home")

  return (
    <div id="main-app">
      <MainSliders />
      <AppsList
        title={t.home.latestAppsAndGames}
        apps={apps}
        isPending={isFetchingNextPage}
      />
      <div ref={ref}></div>
    </div>
  )
}
