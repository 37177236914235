import { ReactElement } from "react"
import Image from "next/image"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, EffectCoverflow } from "swiper/modules"
import "swiper/css"
import "swiper/css/effect-coverflow"
import useMediaQuery from "utils/useMediaQuery"
import Link from "next/link"
import MountIfVisible from "components/MountIfVisible"
import imagePlaceholder from "utils/imagePlaceholder"
import useGlobalStore from "stores/global"
import addToHomeScreen from "views/articles/AddToHomeScreen"
import useT from "utils/useT"
import submitYourApp from "views/articles/SubmitYourApp"
import BestInstagramApps from "views/articles/BestInstagramApps"
import BestNoteTakingApps from "views/articles/BestNoteTakingApps"
import BestQuranApps from "views/articles/BestQuranApps"
import BestAudioApps from "views/articles/BestAudioApps"
import MoviesApps from "views/articles/MoviesApps"

interface Props {
  thumbnail: string
  href: string
  title: string
  subtitle: string
}

function ArticleCard({ thumbnail, href, title, subtitle }: Props) {
  const isDark = useGlobalStore((state) => state.isDark)

  return (
    <Link href={href}>
      <div className="splide px-4" style={{ visibility: "visible" }}>
        <div className="card rounded-l shadow-l" style={{ height: 270 }}>
          <div className="card-bottom">
            <h2 dir="auto" className="font-17 text-center font-700 mb-0 px-2">
              {title}
            </h2>
            <p dir="auto" className="boxed-text-xl font-12 mb-3">
              {subtitle}
            </p>
          </div>
          <div className="card-overlay bg-gradient-fade"></div>
          <Image
            fill
            className="card-bg"
            src={thumbnail || imagePlaceholder(isDark)}
            alt="app screenshot"
            placeholder="blur"
            style={{ objectFit: "cover" }}
            blurDataURL={imagePlaceholder(isDark)}
            unoptimized
          />
        </div>
      </div>
    </Link>
  )
}
export default function SliderCarousel(): ReactElement {
  const t = useT()
  const isDesktop = useMediaQuery("(min-width: 900px)")
  const slides = [
    {
      href: MoviesApps[t.language]?.href,
      thumbnail: MoviesApps[t.language]?.thumbnail,
      title: MoviesApps[t.language]?.title,
      subtitle: MoviesApps[t.language]?.subtitle,
    },
    {
      href: BestQuranApps[t.language]?.href,
      thumbnail: BestQuranApps[t.language]?.thumbnail,
      title: BestQuranApps[t.language]?.title,
      subtitle: BestQuranApps[t.language]?.subtitle,
    },
    {
      href: BestAudioApps[t.language]?.href,
      thumbnail: BestAudioApps[t.language]?.thumbnail,
      title: BestAudioApps[t.language]?.title,
      subtitle: BestAudioApps[t.language]?.subtitle,
    },
    {
      href: addToHomeScreen[t.language]?.href,
      thumbnail: addToHomeScreen[t.language]?.thumbnail,
      title: addToHomeScreen[t.language]?.title,
      subtitle: addToHomeScreen[t.language]?.subtitle,
    },
    {
      href: BestInstagramApps[t.language]?.href,
      thumbnail: BestInstagramApps[t.language]?.thumbnail,
      title: BestInstagramApps[t.language]?.title,
      subtitle: BestInstagramApps[t.language]?.subtitle,
    },
    {
      href: submitYourApp[t.language]?.href,
      thumbnail: submitYourApp[t.language]?.thumbnail,
      title: submitYourApp[t.language]?.title,
      subtitle: submitYourApp[t.language]?.subtitle,
    },
    {
      href: BestNoteTakingApps[t.language]?.href,
      thumbnail: BestNoteTakingApps[t.language]?.thumbnail,
      title: BestNoteTakingApps[t.language]?.title,
      subtitle: BestNoteTakingApps[t.language]?.subtitle,
    },
  ]
  return (
    <Swiper
      effect={"coverflow"}
      modules={[EffectCoverflow, Autoplay]}
      grabCursor={true}
      autoplay={{
        delay: 3500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      loop
      centeredSlides={true}
      slidesPerView={isDesktop ? 2.232 : 1.02}
      coverflowEffect={{
        depth: isDesktop ? 250 : 150,
        rotate: -10,
        stretch: 5,
        modifier: 1.8,
        slideShadows: false,
      }}
      speed={700}
      className="mySwiper"
    >
      {slides.map((item, i) => (
        <SwiperSlide key={i + "article-slide"}>
          <MountIfVisible i={i}>
            <ArticleCard
              key={i + "article-slide"}
              thumbnail={item.thumbnail}
              title={item.title}
              href={item.href}
              subtitle={item.subtitle}
            />
          </MountIfVisible>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
