import { AppInterface } from "./../types/types"
const app: AppInterface = {
  artworkUrl60: "",
  artworkUrl512: "",
  artworkUrl100: "",
  artistViewUrl: "",
  trackCensoredName: "Loading...",
  formattedPrice: "$$$",
  trackName: "Loading...",
  trackId: 1,
  primaryGenreName: "Loading...",
  releaseNotes: "Loading...",
  description: "▆▆▆▆▆▆▆▆▆▆▆▆▆▆▆▆",
  artistName: "Loading...",
  price: "$$$",
  genres: ["apps"],
}
const game: AppInterface = {
  artworkUrl60: "",
  artworkUrl512: "",
  artworkUrl100: "",
  artistViewUrl: "",
  trackCensoredName: "Loading...",
  formattedPrice: "$$$",
  trackName: "Loading...",
  trackId: 1,
  primaryGenreName: "Loading...",
  releaseNotes: "Loading...",
  description: "▆▆▆▆▆▆▆▆▆▆▆▆▆▆▆▆",
  artistName: "Loading...",
  price: "$$$",
  genres: ["Games"],
}
const games = [...new Array(10)].map((n, i) => ({
  ...game,
  trackId: i + 1.1,
  primaryGenreName: "Games",
}))
const apps = [...new Array(10)].map((n, i) => ({
  ...app,
  trackId: i + 2.2,
  primaryGenreName: "Apps",
}))
export const appsList = [...games, ...apps]
